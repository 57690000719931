import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import './App.css';
import './Vignettes.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import VideoPlayer from './VideoPlayer';
import { initializeApp } from 'firebase/app';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { useHistory } from 'react-router-dom'; // Import pour la navigation locale

// Configuration Firebase
const firebaseConfig = {
  apiKey: "AIzaSyCsSFYi0hwxC8XL5dDZ-hE1gn7LhS2Z6nw",
  authDomain: "x7links-dae3d.firebaseapp.com",
  projectId: "x7links-dae3d",
  storageBucket: "x7links-dae3d.appspot.com",
  messagingSenderId: "237718255131",
  appId: "1:237718255131:web:91b3cacdaa9eaf952a2abe",
  measurementId: "G-W9LQPQQ619"
};

// Initialisation de Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const Vignettes = () => {
  const [vignettes, setVignettes] = useState([]);
  const [activeVideoIndex, setActiveVideoIndex] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const history = useHistory(); // Utilisation du hook pour la navigation locale

  // Charger les données de Firestore
  const loadVignettesFromFirebase = async () => {
    const docRef = doc(db, 'Vignettes', 'homevideo');
    try {
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const videoData = docSnap.data().video || [];
        setVignettes(videoData);
      } else {
        console.log('Aucun document trouvé !');
        setError('Aucun document trouvé !');
      }
    } catch (error) {
      console.error('Erreur lors de la récupération du document :', error);
      setError('Erreur lors de la récupération des données.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadVignettesFromFirebase();
  }, []);

  if (loading) {
    return <div style={{ color: '#ffffff' }}>Loading...</div>;
  }

  if (error) {
    return <div style={{ color: '#ff0000' }}>{error}</div>;
  }

  // Fonction pour gérer le clic sur une vignette
  const handleVignetteClick = (vignette) => {
    if (vignette.externalLink) {
      // Si externalLink est présent, redirige vers l'URL externe
      window.open(vignette.externalLink, '_blank', 'noopener,noreferrer');
    } else if (vignette.link) {
      // Sinon, redirige vers la page interne
      history.push(vignette.link);
    } else {
      console.error("Le lien de la vignette est invalide ou manquant.");
    }
  };

  return (
    <div style={{ minHeight: '100vh', backgroundColor: '#1a1a1a', paddingBottom: '20px' }}>
      <Helmet>
        <title>Top 7 Porn videos of the Week - HD Rankings - x7links [Porn Rank]</title>
        <meta name="description" content="Welcome to X7links.com! Explore the best free quality porn videos. Discover 7 of the highest quality top sex videos in 7 categories, updated weekly for your ultimate viewing experience." />
        <meta name="keywords" content="best category porn, best rank porn, adult videos, HD porn, xxx, x video, X video, erotic content, free high-quality porn, best adult videos of the week, premium adult videos online, weekly rankings of adult content, watch free HD porn. [Porn Rank]" />
        <meta property="og:title" content="Category Videos on x7links [Porn Rank]" />
        <meta property="og:description" content="Explore the ranking of the best free Full HD porn videos on x7links. Perfect for those who love sex and eroticism." />
        <meta property="og:image" content="https://i.postimg.cc/ncyTbS6G/logo-X7links-1200x630.png" />
        <meta property="og:url" content="https://www.x7links.com/" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Best Rated Adult Content - x7links [Porn Rank]" />
        <meta name="twitter:description" content="Discover and watch the best erotic videos on x7links. Explore top-rated content across various categories and enjoy high-quality streaming in a user-friendly environment. Join our community for exclusive access to premium erotic entertainment." />
        <meta name="twitter:image" content="https://i.postimg.cc/ncyTbS6G/logo-X7links-1200x630.png" />
      </Helmet>

      <h4 className="titlehomepage1">7-day rankings by category of the week's top videos</h4>

      <div className="vignettes-grid" style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
        gap: '10px',
        justifyContent: 'center',
        alignItems: 'stretch',
      }}>
        {vignettes.map((vignette, index) => (
          <div key={index} style={{ position: 'relative', borderRadius: '8px', overflow: 'hidden' }}>
            <div
              className="vignette"
              style={{
                backgroundColor: '#333',
                borderRadius: '8px',
                overflow: 'hidden',
                position: 'relative',
                textDecoration: 'none',
                cursor: 'pointer',
              }}
              onClick={() => handleVignetteClick(vignette)} // Gère le clic sur la vignette
              onMouseEnter={() => setActiveVideoIndex(index)}
              onMouseLeave={() => setActiveVideoIndex(null)}
            >
              <img
                src={vignette.imgSrc}
                alt={vignette.category}
                style={{
                  width: '100%',
                  height: '200px',
                  objectFit: 'cover',
                  borderRadius: '8px',
                  marginBottom: '1rem',
                  display: activeVideoIndex === index ? 'none' : 'block',
                }}
              />
              {activeVideoIndex === index && (
                <VideoPlayer
                  videoSrc={vignette.videoSrc}
                  isPlaying={true}
                  muted={true}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    borderRadius: '8px',
                    zIndex: 1,
                  }}
                />
              )}
            </div>
            <div className="vignette-content" style={{
              padding: '12px',
              position: 'relative',
              zIndex: 2,
            }}>
              <div className="vignette-rank" style={{
                fontWeight: 600,
                color: '#ffffff',
                fontSize: '0.9em',
                marginBottom: '8px',
              }}>
                {vignette.rank}
              </div>
            </div>

            {/* Ajout d'un div autour du texte de la catégorie avec onClick */}
            <div
              className="vignette-category-wrapper"
              onClick={() => handleVignetteClick(vignette)} // Navigation au clic sur le texte
              style={{
                cursor: 'pointer',
              }}
            >
              <div
                className="vignette-category"
                style={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  backgroundColor: '#333333',
                  color: '#ffffff',
                  padding: '2px',
                  textAlign: 'left',
                  fontWeight: 250,
                  fontSize: '1.6em',
                  zIndex: 2,
                }}
              >
                {vignette.category}
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* START Barre de séparation */}
      <hr style={{
        background: 'linear-gradient(to right, #ffa31a, #ffa31a)',
        height: '20px',
        border: '20px',
        margin: '20px auto',
        borderRadius: '5px',
        width: '61%',
        animation: 'shine 2s infinite', // Animation de brillance
      }} />

      <style>
        {`
          @keyframes shine {
            0% { opacity: 0.7; }
            50% { opacity: 1; }
            100% { opacity: 0.7; }
          }
        `}
      </style>
      {/* FIN Barre de séparation */}

      {/* DÉBUT DU NOUVEAU SCRIPT */}




{/* FIN DU NOUVEAU SCRIPT */}
    </div>
    
    
  );
};

export default Vignettes;
