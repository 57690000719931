import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import './styles.css';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { doc, getDoc } from 'firebase/firestore';
import { db } from './firebase';

const BigAssVignettes = () => {
  const currentCategory = "big-ass"; // Spécifiez la catégorie actuelle ici  
  const [bigAssVignettes, setBigAssVignettes] = useState([]);
  const [loading, setLoading] = useState(true); // État de chargement
  const [title, setTitle] = useState('7-day ranking of the top big ass videos'); // Titre par défaut

  // Fonction pour récupérer les vignettes de Firebase
  const fetchVignettes = async () => {
    try {
      const docRef = doc(db, 'categories', 'bigass'); // Changez ici pour 'bigass'
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists()) {
        const data = docSnap.data();
        console.log("Données récupérées :", data); // Pour voir les données récupérées dans la console
        setBigAssVignettes(data.vignettes || []); // Assurez-vous que la structure est correcte
        setTitle(data.title || title); // Récupération du titre depuis Firebase
      } else {
        console.error("Aucun document trouvé !");
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des données :", error);
    } finally {
      setLoading(false); // Arrêtez l'indicateur de chargement
    }
  };

  useEffect(() => {
    fetchVignettes(); 
  }, []);

  return (
    <>
      <Helmet>
        <title>Top 7 Big Ass Porn videos of the Week - HD Rankings - x7links [Porn Rank]</title>
        <meta name="description" content="Watch the top Big Ass erotic videos on x7links." />
        <meta name="keywords" content="porn rank, rank porn, pornrank, rankporn, xrank,big ass porn, big ass videos, xxx, x video, X video, x porn, best big ass content, big ass HD videos, top big ass porn, free big ass videos, weekly big ass rankings, big ass erotic content, big ass porn in HD, videos de cul grande, mejores videos de cul grande, pornografía de cul grande en HD, ranking semanal de videos de cul grande, x7links" />
        <meta property="og:title" content="Big Ass Videos on x7links [Porn Rank]" />
        <meta property="og:description" content="Explore the best Big Ass videos fullHD online." />
        <meta property="og:image" content="https://i.postimg.cc/ncyTbS6G/logo-X7links-1200x630.png" />
        <meta property="og:url" content="https://www.x7links.com/big-ass" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Big Ass Videos - x7links [Porn Rank]" />
        <meta name="twitter:description" content="Watch the top Big Ass erotic videos on x7links [Porn Rank]." />
        <meta name="twitter:image" content="https://i.postimg.cc/ncyTbS6G/logo-X7links-1200x630.png" />
      </Helmet>
      
      <div style={{ minHeight: '100vh', backgroundColor: '#1a1a1a', paddingBottom: '20px' }}>
        <h4 className="titlecatpage1">{title}</h4>
        
        {loading ? (  
          <div style={{ color: '#ffffff' }}>Chargement des vignettes...</div>
        ) : (
          <div className="vignettes-grid" style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
            gap: '20px',
            maxWidth: '1200px',
            margin: '0 auto',
            padding: '20px',
          }}>
            {bigAssVignettes.length > 0 ? (
              bigAssVignettes.map((vignette, index) => (
                <a key={index} href={vignette.externalLink} target="_blank" rel="noopener noreferrer" className="vignette" style={{
                  backgroundColor: '#333',
                  borderRadius: '8px',
                  overflow: 'hidden',
                  position: 'relative',
                  textDecoration: 'none',
                  transition: 'transform 0.2s',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                }}>
                  <img
                    src={vignette.imgSrc}
                    alt={vignette.description}
                    onError={(e) => { e.target.src = 'fallback-image-url.jpg'; }} // Gestion de l'erreur d'image
                    style={{
                      width: '100%',
                      height: 'auto',
                      objectFit: 'cover',
                    }}
                  />
                  <div className="vignette-content" style={{
                    padding: '12px',
                    backgroundColor: '#444',
                    borderTopLeftRadius: '0',
                    borderTopRightRadius: '0',
                    zIndex: 1,
                  }}>
                    <div style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginBottom: '4px',
                    }}>
                      <div className="vignette-rank" style={{
                        fontWeight: 600,
                        color: '#ffc107',
                        fontSize: '1.2em',
                      }}>{vignette.rank}</div>
                      <div className="vignette-views" style={{
                        color: '#ffffff',
                        fontSize: '0.9em',
                      }}>{vignette.views} views</div>
                    </div>
                    <div className="vignette-description" style={{
                      color: '#ffffff',
                      fontSize: '0.9em',
                      lineHeight: 1.4,
                      textAlign: 'justify',
                    }}>{vignette.description}</div>
                  </div>
                </a>
              ))
            ) : (
              <div style={{ color: '#ffffff' }}>Aucune vignette disponible.</div>
            )}
          </div>
        )}
        
        {/* Barre de séparation */}
        <hr className="separator" />

        {/* Boutons de catégorie */}
        <div className="category-buttons">
          {['Solo-Girl', 'Lesbian', 'Threesome', 'Big-Ass', 'Teen', 'Blow-Job', 'Milf', 'Bonus'].map((category, index) => (
            category.toLowerCase() !== currentCategory ? ( // Vérifiez si ce n'est pas la catégorie actuelle
              <Link key={index} to={`/${category.toLowerCase()}`} className="category-button">
                {category}
              </Link>
            ) : null // Si c'est la catégorie actuelle, ne rien rendre
          ))}
          {/* Lien vers la page d'accueil avec le texte "Category" */}
  <Link to="/" className="category-button">
    Category
  
  </Link>
        </div>
      </div>
    </>
  );
};

export default BigAssVignettes;
