import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./AdvertiserPage.css";

const AdvertiserPage = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [price, setPrice] = useState(10);
  const [email, setEmail] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("PayPal");

  const calculatePrice = (start, end) => {
    const msPerDay = 1000 * 60 * 60 * 24;
    const days = Math.ceil((end - start) / msPerDay);

    if (days < 7) return "Minimum 7 days required";
    if (days > 30) return "Maximum 30 days allowed";

    const basePrice = 10;
    const pricePerDay = 1.43;
    return basePrice + (days - 7) * pricePerDay;
  };

  const handleDateChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
    const updatedPrice = calculatePrice(start, end);
    setPrice(updatedPrice);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("email", email);
    formData.append("startDate", startDate.toDateString());
    formData.append("endDate", endDate.toDateString());
    formData.append("price", price);
    formData.append("paymentMethod", paymentMethod);
  
    fetch("https://formspree.io/f/mvgorrag", {
      method: "POST",
      body: formData,
      headers: {
        "Accept": "application/json", // Ajoute cette ligne pour spécifier que la réponse sera en JSON
      },
    })
      .then((response) => {
        if (response.ok) {
          alert("Request sent successfully!");
        } else {
          alert("There was an error with the request. Please try again.");
        }
      })
      .catch((error) => {
        console.error("Error with the request:", error);
        alert("There was an error with the request.");
      });
  };

  return (
    <div className="advertiser-page">
      <h1 className="main-title">Advertise with x7Links</h1>
      <h2 className="subtitle">Maximize Your Brand's Visibility</h2>

      <p className="description">
        Choose your preferred advertising dates and see a dynamic price estimate. Get your brand featured on our main page and reach a targeted audience.
      </p>

      <div className="calendar-module">
        <h3>Select Your Campaign Dates</h3>
        <div className="date-picker">
          <label>Start Date:</label>
          <DatePicker
            selected={startDate}
            onChange={(date) => handleDateChange(date, endDate)}
            minDate={new Date()}
          />
        </div>
        <div className="date-picker">
          <label>End Date:</label>
          <DatePicker
            selected={endDate}
            onChange={(date) => handleDateChange(startDate, date)}
            minDate={new Date(startDate.getTime() + 7 * 24 * 60 * 60 * 1000)}
            maxDate={new Date(startDate.getTime() + 30 * 24 * 60 * 60 * 1000)}
          />
        </div>
        <p className="price-estimate">Estimated Price: {typeof price === "string" ? price : `$${price}`}</p>
        <p className="description">The price is $1.43 per day, with a minimum duration of 7 days and a maximum of 30 days. Please allow 6 days from your request to set up your advertisement.</p>
      </div>

      <div className="payment-module">
        <h3>Payment Method</h3>
        <select value={paymentMethod} onChange={(e) => setPaymentMethod(e.target.value)}>
          <option value="PayPal">PayPal</option>
          <option value="Revolut">Revolut</option>
          <option value="Crypto">Crypto (Bitcoin, Ethereum, USDT, USDC)</option>
        </select>
      </div>

      <form onSubmit={handleSubmit} className="contact-form">
        <h3>Submit Your Advertising Request</h3>
        <p className="description">We will get back to you quickly via email to send you a quote and more information to set up your advertisement.</p>

        <input
          type="email"
          placeholder="Your Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button type="submit" className="submit-btn">Submit Request</button>
      </form>
    </div>
  );
};

export default AdvertiserPage;
