import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import './ShopPage.css'; // Import du fichier CSS
import './App.css'; // Si tu utilises un fichier CSS séparé
import 'bootstrap/dist/css/bootstrap.min.css';
import { doc, getDoc } from 'firebase/firestore';
import { db } from './firebase'; // Assurez-vous que le chemin est correct
import { Link } from 'react-router-dom';


// Composant pour afficher une vignette
const Vignette = ({ vignette }) => {
  return (
    <a
      href={vignette.link}
      target="_blank" // Ouvre le lien dans un nouvel onglet
      rel="noopener noreferrer" // Sécurité pour éviter les problèmes de sécurité
      className="vignette items"
      style={{
        backgroundColor: '#333',
        borderRadius: '8px',
        overflow: 'hidden',
        position: 'relative',
        textDecoration: 'none',
        color: 'white',
      }}
    >
      <img
        src={vignette.imgScr}
        alt={vignette.category}
        className="vignette-img items"
      />
      <div className="vignette-content items">
        <div className="vignette-rank items">{vignette.rank}</div>
        <div className="vignette-description items">{vignette.description}</div>
      </div>
      <div className="vignette-category items">
        {vignette.category}
      </div>
    </a>
  );
};

// Composant pour la page Shop
const ShopPage = () => {
  const [products, setProducts] = useState([]); // Initialiser le tableau de produits

  // Fonction pour interagir avec Firebase (lecture)
  const interactWithFirebase = async () => {
    try {
      const docRef = doc(db, 'products', 'shopItems');
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        if (data.products && Array.isArray(data.products)) {
          setProducts(data.products);
          console.log("Produits récupérés :", data.products); // Log des produits
        } else {
          console.error("Le champ 'products' est absent ou n'est pas un tableau.");
        }
      } else {
        console.log("Aucun document trouvé !");
      }
    } catch (error) {
      console.error("Erreur lors de l'accès à Firebase :", error);
    }
  };

  useEffect(() => {
    interactWithFirebase(); // Appelle la fonction lors du premier rendu
  }, []);

  return (
    <div>
      <Helmet>
        <title>Best Adult Toys Selection - Shop Fast, Discreet & Quality Service - x7links [Porn Rank]</title>
        <meta name="description" content="Discover the best selection of erotic adult toys at x7links. Enjoy fast, discreet, and quality service, with products designed to enhance your intimate moments. Explore our range now!" />
        <meta name="keywords" content="porn rank, rank porn, pornrank, rankporn, xrank, x rank, adult toys, erotic shop, high-quality adult products, xxx, x video, X video, discreet shopping, best adult toys selection, premium erotic accessories, top-rated adult products, fast shipping adult toys, online erotic shop, toys para adultos, accesorios eróticos de alta calidad, mejores juguetes para adultos, compra discreta, juguetes eróticos de lujo, tienda de placer, juguetes íntimos, regalos para adultos, x7links" />
        <meta property="og:title" content="Shop on x7links" />
        <meta property="og:description" content="Explore the best selection of adult toys at x7links[Porn Rank]." />
        <meta property="og:image" content="https://i.postimg.cc/ncyTbS6G/logo-X7links-1200x630.png" />
        <meta property="og:url" content="https://www.x7links.com/shop" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Adult Toys - x7links" />
        <meta name="twitter:description" content="Explore the best selection of adult toys at x7links. Enjoy fast, discreet service and high-quality products designed to enhance your intimate moments." />
        <meta name="twitter:image" content="https://i.postimg.cc/ncyTbS6G/logo-X7links-1200x630.png" />
      </Helmet>
      
      <div className="shop-page">
        <div className="shop-page-header">
          <h1>Our Shop Page</h1>
          <p>Discover the perfect affiliate products we’ve handpicked for you!</p>
        </div>
        <div className="vignettes-grid-items" style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))', // 3 colonnes ou plus si l'écran est plus large
          gap: '20px', // Espace entre les vignettes
          maxWidth: '1200px',
          margin: '0 auto',
          padding: '20px',
        }}>
          {products.length > 0 ? (
            products.map((vignette, index) => (
              <Vignette key={index} vignette={vignette} />
            ))
          ) : (
            <p>Aucun produit à afficher.</p>
          )}
        </div>
        {/* Boutons de catégorie */}
      <div className="category-buttons">
        {['Solo-Girl', 'Lesbian', 'Threesome', 'Big-Ass', 'Teen', 'Blow-Job', 'Milf', 'Bonus'].map((category, index) => (
          <Link key={index} to={`/${category.toLowerCase()}`} className="category-button">
            {category}
          </Link>
        ))}
        <Link to="/" className="category-button">
          Category
        </Link>
      </div>
      </div>
    </div>
  );
};

export default ShopPage;
