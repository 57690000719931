import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import './styles.css';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { doc, getDoc } from 'firebase/firestore';
import { db } from './firebase';
import { Link } from 'react-router-dom';

const CamGirlVignettes = () => {
  const [camGirlVignettes, setCamGirlVignettes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentCategory, setCurrentCategory] = useState('CamGirl'); // Catégorie actuelle

  // Fonction pour récupérer les vignettes de Firebase
  const fetchVignettes = async () => {
    try {
      const docRef = doc(db, 'categories', 'camgirl'); // Assurez-vous que la collection est correcte
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        console.log("Données récupérées :", data);
        setCamGirlVignettes(data.vignettes || []);
      } else {
        console.error("Aucun document trouvé !");
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des données :", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchVignettes();
  }, []);

  return (
    <div style={{ minHeight: '100vh', backgroundColor: '#1a1a1a', paddingBottom: '20px' }}>
      <Helmet>
        <title>Top 7 Cam Girl Porn videos of the Week - HD Rankings - x7links [Porn Rank]</title>
        <meta name="description" content="Watch the top Cam Girl erotic videos on x7links [Porn Rank]." />
        <meta name="keywords" content="porn rank, rank porn, pornrank, rankporn, xrank, x rank, cam girl, cam girl porn, xxx, adult videos, HD porn, erotic content, free high-quality porn, weekly rankings, x7links" />
        <meta property="og:title" content="Cam Girl Videos on x7links [Porn Rank]" />
        <meta property="og:description" content="Explore the best Cam Girl videos fullHD online." />
        <meta property="og:image" content="https://i.postimg.cc/ncyTbS6G/logo-X7links-1200x630.png" />
        <meta property="og:url" content="https://www.x7links.com/cam-girl" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Cam Girl Videos - x7links [Porn Rank]" />
        <meta name="twitter:description" content="Watch the top Cam Girl erotic videos on x7links [Porn Rank]." />
        <meta name="twitter:image" content="https://i.postimg.cc/ncyTbS6G/logo-X7links-1200x630.png" />
      </Helmet>
      
      <h4 className="titlecatpage1">Ranking of the top Cam Girl videos</h4>

      

      {loading ? (
        <div style={{ color: '#ffffff' }}>Loading...</div>
      ) : (
        <div className="vignettes-grid" style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
          gap: '20px',
          maxWidth: '1200px',
          margin: '0 auto',
          padding: '20px',
        }}>
          {camGirlVignettes.length > 0 ? (
            camGirlVignettes.map((vignette, index) => (
              <a key={index} href={vignette.externalLink} target="_blank" rel="noopener noreferrer" className="vignette" style={{
                backgroundColor: '#333',
                borderRadius: '8px',
                overflow: 'hidden',
                position: 'relative',
                textDecoration: 'none',
                transition: 'transform 0.2s',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
              }}>
                <img
                  src={vignette.imgSrc}
                  alt={vignette.description}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = 'fallback-image-url.png'; // Remplace avec une image par défaut
                  }}
                  style={{
                    width: '100%',
                    height: 'auto',
                    objectFit: 'cover',
                  }}
                />
                <div className="vignette-content" style={{
                  padding: '12px',
                  backgroundColor: '#444',
                  borderTopLeftRadius: '0',
                  borderTopRightRadius: '0',
                  zIndex: 1,
                }}>
                  <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '4px',
                  }}>
                    <div className="vignette-rank" style={{
                      fontWeight: 600,
                      color: '#ffc107',
                      fontSize: '1.2em',
                    }}>{vignette.rank}</div>
                    <div className="vignette-views" style={{
                      color: '#ffffff',
                      fontSize: '0.9em',
                    }}>{vignette.views} views</div>
                  </div>
                </div>
              </a>
            ))
          ) : (
            
            <div style={{ color: '#ffffff' }}>Aucune vignette disponible.</div>
          )}
        </div>
        
      )}

      {/* Barre de séparation */}
      <hr className="separator" />

       {/* Boutons de catégorie */}
       <div className="category-buttons" style={{ marginBottom: '20px' }}>
        {['Solo-Girl', 'Lesbian', 'Threesome', 'Big-Ass', 'Teen', 'Blow-Job', 'Milf', 'Bonus'].map((category, index) => (
          category.toLowerCase() !== currentCategory.toLowerCase() ? (
            <Link key={index} to={`/${category.toLowerCase()}`} className="category-button" style={{
              margin: '5px',
              padding: '10px 20px',
              backgroundColor: '#444',
              color: '#fff',
              textDecoration: 'none',
              borderRadius: '5px',
              transition: 'background-color 0.3s',
            }}>
              {category}
            </Link>
          ) : null
        ))}
        <Link to="/" className="category-button" style={{
          margin: '5px',
          padding: '10px 20px',
          backgroundColor: '#444',
          color: '#fff',
          textDecoration: 'none',
          borderRadius: '5px',
          transition: 'background-color 0.3s',
        }}>
          Category
        </Link>
      </div>
    </div>
  );
};

export default CamGirlVignettes;
