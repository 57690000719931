import React from 'react';
import { Helmet } from 'react-helmet';

const GeneralTerms = () => {
  return (
    <div className="terms-container">
      <Helmet>
        <title>General Terms and Conditions - x7links</title>
        <meta name="description" content="Read the General Terms and Conditions for using x7links.com." />
      </Helmet>

      <h1>General Terms and Conditions</h1>

      <section>
        <h2>1. Introduction</h2>
        <p>
          Welcome to x7links.com. By accessing and using our website, you agree to comply with and be bound by
          the following terms and conditions of use, which govern x7links.com's relationship with you in relation
          to this website.
        </p>
      </section>

      <section>
        <h2>2. Age Requirement</h2>
        <p>
          This website is intended for adults only. You must be at least 18 years old (or the age of majority in
          your jurisdiction) to access and use this website. By using this website, you confirm that you meet the
          age requirement.
        </p>
      </section>

      <section>
        <h2>3. Intellectual Property Rights</h2>
        <p>
          All content on x7links.com, including but not limited to text, images, logos, and videos, is owned by or
          licensed to us. Unauthorized use of this content is prohibited and may lead to legal action.
        </p>
      </section>

      <section>
        <h2>4. User Conduct</h2>
        <p>
          By using our website, you agree not to post, share, or transmit any content that is illegal, harmful,
          defamatory, or violates any applicable laws. You also agree not to interfere with the security or
          operation of our website.
        </p>
      </section>

      <section>
        <h2>5. Disclaimer of Warranties</h2>
        <p>
          This website is provided on an "as is" basis. x7links.com makes no representations or warranties of any
          kind, express or implied, as to the operation of the website or the information, content, or materials
          included on this site.
        </p>
      </section>

      <section>
        <h2>6. Limitation of Liability</h2>
        <p>
          x7links.com shall not be liable for any damages arising out of or in connection with your use of this
          website. This includes, without limitation, direct, indirect, incidental, punitive, and consequential
          damages.
        </p>
      </section>

      <section>
        <h2>7. Changes to Terms</h2>
        <p>
          We may update our terms and conditions from time to time. Please check this page periodically to ensure
          you are aware of any changes. Your continued use of the site constitutes acceptance of these changes.
        </p>
      </section>

      <section>

      <h1>Privacy Policy</h1>

      <section>
        <h2>1. Introduction</h2>
        <p>
        Welcome to x7links.com. This privacy policy describes how we collect, use, and protect your personal information when you visit our website.
        </p>
      </section>

      <section>
        <h2>2. Information We Collect</h2>
        <p>
        We collect the following types of information:

        Personal Information: When you register, we may collect information such as your name, email address, and other contact details.
        Usage Data: We collect data about your use of our site, including your IP address, browser type, pages visited, and time spent on our site.
        </p>
      </section>

      <section>
        <h2>3. How We Use Your Information</h2>
        <p>
        We use the information collected for:

        Providing, maintaining, and improving our website.
        Communicating with you regarding your account or our services.
        Analyzing site usage to enhance our services.
        </p>
      </section>

      <section>
        <h2>4. Sharing Your Information</h2>
        <p>
        We do not sell, trade, or transfer your personal information to third parties without your consent, except in the following cases:

        To comply with the law or respond to legal requests.
        To protect our rights or those of others.
        </p>
      </section>

      <section>
        <h2>5. Security of Your Information</h2>
        <p>
        We implement reasonable security measures to protect your personal information. However, no method of transmission over the Internet or electronic storage is 100% secure.
        </p>
      </section>

      <section>
        <h2>6. Your Rights</h2>
        <p>
        Under GDPR, you have the right to:

        Access your personal information.
        Request correction of your information.
        Request deletion of your information.
        Restrict or object to the processing of your data.
        </p>
      </section>

      <section>
        <h2>7. Changes to This Policy</h2>
        <p>
        We reserve the right to modify this privacy policy. We will notify you of any changes by posting the new policy on this page.
        </p>
      </section>


        <h2>Contact Information</h2>
        <p>
          If you have any questions or concerns about our General Terms and Conditions, please contact us at{' '}
          <a href="https://x.com/x7links">@x7links on x.com</a>.
        </p>
      </section>

      <footer>
        <p>&copy; 2024 x7links. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default GeneralTerms;
