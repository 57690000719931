// src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Remplacez les valeurs par celles de votre configuration Firebase
const firebaseConfig = {
    apiKey: "AIzaSyCsSFYi0hwxC8XL5dDZ-hE1gn7LhS2Z6nw",
    authDomain: "x7links-dae3d.firebaseapp.com",
    projectId: "x7links-dae3d",
    storageBucket: "x7links-dae3d.appspot.com",
    messagingSenderId: "237718255131",
    appId: "1:237718255131:web:91b3cacdaa9eaf952a2abe",
    measurementId: "G-W9LQPQQ619"
};

// Initialiser Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
