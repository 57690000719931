// VideoPlayer.js
import React from 'react';
import ReactPlayer from 'react-player';

const VideoPlayer = ({ videoSrc, isPlaying = false, muted = false }) => {
  return (
    <div style={{ position: 'relative', paddingTop: '56.25%' }}> {/* Pour un ratio 16:9 */}
      <ReactPlayer
        url={videoSrc}
        playing={isPlaying}
        muted={muted}
        loop={true}
        width="100%"
        height="100%"
        controls={true} // Affiche les contrôles de lecture
        style={{ position: 'absolute', top: 0, left: 0 }}
      />
    </div>
  );
};

export default VideoPlayer;
