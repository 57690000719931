import React from 'react';

const AdBanner = () => {
  React.useEffect(() => {
    // Charger l'annonce JuicyAds
    (window.adsbyjuicy = window.adsbyjuicy || []).push({'adzone': 1072368});
  }, []);

  return (
    <div style={{ textAlign: 'center', margin: '20px 0' }}>
      <ins id="1072368" data-width="300" data-height="100"></ins>
    </div>
  );
};

export default AdBanner;
