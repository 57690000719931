import React, { useEffect, useState } from 'react'; 
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { db } from './firebase';  // Utilisation de l'import nommé
import { collection, getDocs, addDoc } from 'firebase/firestore'; // Import des fonctions nécessaires de Firestore
import './MapPage.css'; // Assurez-vous d'avoir créé ce fichier CSS


// Icône personnalisée pour le marqueur
const markerIcon = new L.Icon({
  iconUrl: 'https://i.postimg.cc/28mGWHmP/goupille-de-localisation.png',
  iconSize: [35, 35],
  iconAnchor: [12, 41],
  popupAnchor: [0, -41],
});

const MapPage = () => {
  const [locations, setLocations] = useState([]); // État pour stocker les localisations
  const [newLocation, setNewLocation] = useState({
    title: '',
    description: '',
    latitude: '',
    longitude: '',
    link: ''
  });

  // Récupérer les données de Firestore
  useEffect(() => {
    const fetchLocations = async () => {
      const locationsCollection = collection(db, 'locations'); // Accéder à la collection 'locations'
      const snapshot = await getDocs(locationsCollection); // Obtenir les documents
      const fetchedLocations = snapshot.docs.map(doc => doc.data()); // Récupérer les données de chaque document
      setLocations(fetchedLocations); // Mettre à jour l'état avec les données récupérées
    };

    fetchLocations(); // Appeler la fonction pour récupérer les données
  }, []);

  // Ajouter une nouvelle localisation à Firestore
  const addLocation = async (event) => {
    event.preventDefault();
    if (newLocation.latitude && newLocation.longitude && newLocation.title && newLocation.description && newLocation.link) {
      const locationsCollection = collection(db, 'locations'); // Accéder à la collection 'locations'
      await addDoc(locationsCollection, {
        latitude: parseFloat(newLocation.latitude),
        longitude: parseFloat(newLocation.longitude),
        title: newLocation.title,
        description: newLocation.description,
        link: newLocation.link,
      });

      

      // Mettre à jour l'état de la page pour inclure la nouvelle localisation
      setLocations([...locations, newLocation]);

      // Réinitialiser le formulaire
      setNewLocation({
        title: '',
        description: '',
        latitude: '',
        longitude: '',
        link: ''
      });
    } else {
      alert("Please fill all fields!");
    }
  };

  return (
    <div style={{ minHeight: '100vh', backgroundColor: '#1a1a1a', paddingBottom: '20px' }}>
      <Helmet>
        <title>Interactive Sexe Map - x7links</title>
        <meta name="description" content="Explore our interactive Sexe Map , the best sex shops and erotic clubs worldwide! Enjoy this interactive map for free to easily explore unique and exceptional places. ! L ." />
        <meta name="keywords" content="map, sexe map , sexmap, porn map, explore porn on the world, x7links map, x map , map x porn, sex location, porn localization , world porn,  sexhop, sex club , sexclub , sexe shop, best map porn,  map 2025, interactive map, OpenStreetMap, React, x7links" />
        <meta property="og:title" content="Sex porn map on x7links" />
        <meta property="og:description" content="Explore the best sexe map on the world !." />
        <meta property="og:image" content="https://i.postimg.cc/ncyTbS6G/logo-X7links-1200x630.png" />
        <meta property="og:url" content="https://www.x7links.com/sexmap" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="sexe map - x7links" />
        <meta name="twitter:description" content="Explore our interactive Sexe Map on x7links." />
        <meta name="twitter:image" content="https://i.postimg.cc/ncyTbS6G/logo-X7links-1200x630.png" />
      </Helmet>

      <h4 className="titlecatpage1">Explore the world's best interactive sex map</h4>


      {/* Carte interactive OpenStreetMap */}
      <div style={{ height: '500px', marginTop: '20px' }}>
        <MapContainer center={[51.505, -0.09]} zoom={13} style={{ width: '100%', height: '100%' }}>
          {/* TileLayer de OpenStreetMap */}
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />

          {/* Affichage dynamique des marqueurs sur la carte */}
          {locations.map((location, index) => (
            <Marker key={index} position={[location.latitude, location.longitude]} icon={markerIcon}>
              <Popup>
                <strong>{location.title}</strong><br />
                {location.description}<br />
                <a href={location.link} target="_blank" rel="noopener noreferrer">Learn More</a>
              </Popup>
            </Marker>
          ))}
        </MapContainer>
      </div>
      {/* Barre de séparation */}
      <hr className="separator" />

      {/* Boutons de catégorie */}
      <div className="category-buttons">
        {['Solo-Girl', 'Lesbian', 'Threesome', 'Big-Ass', 'Teen', 'Blow-Job', 'Milf', 'Bonus'].map((category, index) => (
          <Link key={index} to={`/${category.toLowerCase()}`} className="category-button">
            {category}
          </Link>
        ))}
        <Link to="/" className="category-button">
          Category
        </Link>
      </div>

      
    </div>
  );
};

export default MapPage;
