import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import './App.css';
import './styles.css';
import MapPage from'./MapPage';
import BlogPage from'./BlogPage';
import Vignettes from './Vignettes';
import ShopPage from './ShopPage';
import GeneralTerms from './GeneralTerms';
import LesbianVignettes from './LesbianVignettes';
import SoloGirlVignettes from './SoloGirlVignettes';
import ThreesomeVignettes from './ThreesomeVignettes';
import BigAssVignettes from './BigAssVignettes';
import TeenVignettes from './TeenVignettes';
import BlowJobVignettes from './BlowJobVignettes';
import MilfVignettes from './MilfVignettes';
import BonusVignettes from './BonusVignettes';
import SoundPornVignettes from './SoundPornVignettes';
import camgirlVignettes from './CamGirlVignettes';
import AnimeVignettes from './AnimeVignettes';
import OnlyfanVignettes from './OnlyfanVignettes';
import GamePornVignettes from './GamePornVignettes';
import VRPornVignettes from './VRPornVignettes';
import SexDollVignettes from'./SexDollVignettes';
import AdvertiserPage from'./AdvertiserPage';
import { Analytics } from '@vercel/analytics/react';
import 'bootstrap/dist/css/bootstrap.min.css';
import AdBanner from './AdBanner';



//-------------------------Analytics ------------------



    

// ----------------------------- START APP COMPONENT -----------------------------
function App() {
  

//------------GOOGLE Analytics--------------------
  useEffect(() => {
    
    ReactGA.initialize('G-W9LQPQQ619');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
//--------------------------------------------------------------------------------

<>
<h1>Top 7 Porn videos of the Week - HD Rankings</h1>
{/* Contenu de la page */}
</>



//---------------------------------------------------------------------------------
  const initialTimer = localStorage.getItem('timer') 
    ? parseInt(localStorage.getItem('timer'), 10) 
    : 7 * 24 * 60 * 60 * 1000; // 7 jours en ms

  const [timer, setTimer] = useState(initialTimer);
  const [showPopup, setShowPopup] = useState(false);

  // Afficher le popup d'entrée
  const showEntryPopup = () => {
    const lastPopupTimestamp = localStorage.getItem('lastPopup');
    const currentTime = new Date().getTime();

    // Affiche le popup si plus de 30 minutes se sont écoulées
    if (!lastPopupTimestamp || currentTime - lastPopupTimestamp > 1200000) { // 30 minutes en ms
      setShowPopup(true);
      localStorage.setItem('lastPopup', currentTime);
    }
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  // Fonction pour mettre à jour le minuteur
  const updateTimer = () => {
    setTimer((prevTimer) => {
      if (prevTimer <= 1000) {
        localStorage.setItem('timer', 7 * 24 * 60 * 60 * 1000); // Réinitialiser et stocker
        return 7 * 24 * 60 * 60 * 1000; // Réinitialiser à 7 jours
      }
      const newTimer = prevTimer - 1000; // Décrémente le minuteur de 1 seconde
      localStorage.setItem('timer', newTimer); // Mettre à jour le local storage
      return newTimer;
    });
  };

  useEffect(() => {
    showEntryPopup(); // Affiche le popup à la première connexion
    const intervalId = setInterval(updateTimer, 1000); // Met à jour chaque seconde
    return () => clearInterval(intervalId); // Nettoie l'intervalle lors du démontage du composant
  }, []);

  const getTimeRemaining = () => {
    const days = Math.floor(timer / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timer % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timer % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timer % (1000 * 60)) / 1000);
    
    return { days, hours, minutes, seconds };
  };

  const { days, hours, minutes, seconds } = getTimeRemaining();

  return (

    <div>
      <Helmet>
    <title>Top 7 Porn videos of the Week - HD Rankings - x7links [Porn Rank] </title>
    <meta name="description" content="Welcome to X7links.com! Explore the best free quality porn videos. Discover 7 of the highest quality top sex videos in 7 categories, updated weekly for your ultimate viewing experience , vr porn , sexe map , cam girl , sound porn , sexe shop , dating, ai girlfrien , game porn , sex doll shop, Anime video.”." />
    <meta name="keywords" content="porn rank, rank porn, porn ranking, ranking porn, x rank, rank x, adult videos, HD porn, Sexe map, sexmap , sexe localisation, sound porn , porn sound, VRporn, porn VR, Best VR, VR,sexdoll , best sex doll , Anime Porn, cam girl , best gam girl, rank cam girl, Game Porn , best game porn, Best sex shop, xxx, x video, Ai porn, Ai girl , Ai , cam girl, best cam porn, cam porn,  X video, x porn,  erotic content, free high-quality porn, best adult videos of the week, premium adult videos online, weekly rankings of adult content, watch free HD porn, videos para adultos en HD, mejores videos para adultos de la semana, pornografía gratuita de alta calidad, ranking semanal de contenido para adultos, x7links [Porn Rank]" />
    <meta property="og:title" content="Top 7 Porn videos of the Week - HD Rankings [Porn Rank]" />
    <meta property="og:description" content="Welcome to X7links.com! Explore the best free quality porn videos. Discover 7 of the highest quality top sex videos in 7 categories, updated weekly for your ultimate viewing experience. [Porn Rank] ”" />
    <meta property="og:image" content="https://i.postimg.cc/ncyTbS6G/logo-X7links-1200x630.png" />
    <meta property="og:url" content="https://www.x7links.com/" />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content="Best Rated Adult Content - x7links [Porn Rank]" />
    <meta name="twitter:description" content="Discover and watch the best erotic videos on x7links. Explore top-rated content across various categories and enjoy high-quality streaming in a user-friendly environment. Join our community for exclusive access to premium erotic entertainment [Porn Rank]." />
    <meta name="twitter:image" content="https://i.postimg.cc/ncyTbS6G/logo-X7links-1200x630.png" />
    <link rel="canonical" href="https://www.x7links.com/" />
    <meta http-equiv="Content-Language" content="en" />
    <meta name="robots" content="index, follow" />
  
  </Helmet>
  </div>
  ,
    <Router>
      <div style={{ fontFamily: "'Outfit', sans-serif", backgroundColor: '#1a1a1a', color: '#ffffff', minHeight: '100vh', margin: 0 }}>

      <Analytics /> {/* Ajoutez le composant Analytics ici */}

        {/* Pop-up d'entrée */}
        {showPopup && (
          <div className="popup-overlay" style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backdropFilter: 'blur(10px)',
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            zIndex: 1000,
          }}>
            <div className="popup-content" style={{
              backgroundColor: 'rgba(40, 40, 40, 0.9)',
              padding: '35px',
              borderRadius: '10px',
              textAlign: 'center',
              maxWidth: '600px',
              width: '90%',
              overflow: 'hidden',
            }}>
              {/* Logo avec lien */}
              <a href="https://yourwebsite.com" style={{ display: 'block', margin: '5px 0' }}>
                <img 
                  src="https://i.ibb.co/2PmX0xD/13.png" 
                  alt="Logo" 
                  style={{ 
                    width: '220px',
                    height: 'auto',
                    display: 'block',
                    margin: '0 auto',
                  }} 
                />
              </a>

              {/* Titre de vérification d'âge */}
              <h1 className="age-verification-title" style={{ fontSize: '20px', color: '#ffa31a', margin: '8px 0' }}>
                <b>Age Verification</b>
              </h1>

              {/* Contenu de vérification d'âge */}
              <p className="age-verification-content" style={{ 
                display: '-webkit-box', 
                WebkitBoxOrient: 'vertical', 
                WebkitLineClamp: 8,
                overflow: 'hidden', 
                textOverflow: 'ellipsis',
                color: '#ffffff' 
              }}>
                This website contains age-restricted materials including nudity and explicit depictions of sexual activity. By entering, you affirm that you are at least 18 years of age or the age of majority in the jurisdiction you are accessing the website from and you consent to viewing sexually explicit content.
              </p>

              {/* Boutons pour entrer ou quitter */}
              <div style={{ marginTop: '20px' }}>
                <button
                  onClick={closePopup}
                  style={{
                    backgroundColor: '#ffa31a',
                    color: 'white',
                    border: 'none',
                    padding: '10px 40px',
                    fontSize: '18px',
                    cursor: 'pointer',
                    margin: '0 10px',
                  }}
                >
                  Enter +18
                </button>
                <button
                  onClick={() => (window.location.href = 'https://www.google.com')}
                  style={{
                    backgroundColor: '#333',
                    color: 'white',
                    border: 'none',
                    padding: '10px 55px',
                    fontSize: '18px',
                    cursor: 'pointer',
                  }}
                >
                  Leave
                </button>
              </div>

              {/* Informations parentales */}
              <p style={{ marginTop: '20px', color: '#ffffff' }}>
                Our{' '}
                <a
                  href="https://www.asacp.org/index.html?content=parental_guidelines"
                  style={{ color: '#ffa31a', textDecoration: 'underline' }}
                >
                  parental controls page
                </a>{' '}
                explains how you can easily block access to this site. "By clicking on the 'Enter +18' button, you accept the {' '}
                <a
                  href="/general-terms"
                  style={{ color: '#ffa31a', textDecoration: 'underline' }}
                >
                  general terms
                </a>{' '} of use and {' '}
                <a
                  href="/general-terms"
                  style={{ color: '#ffa31a', textDecoration: 'underline' }}
                >
                  privacy policy
                </a>{' '} in compliance with the RGPD..
              </p>
            </div>
          </div>
        )}

        {/* Header avec logo */}
        <header className="custom-header">
          <Link to="/" className="custom-logo-link">
            <img 
              src="https://i.ibb.co/2PmX0xD/13.png" 
              alt="x7links logo" 
              className="responsive-logo"
            />
          </Link>
        </header>

        {/* Navigation */}
        <nav style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#333333', padding: '8px 0', position: 'relative' }}>
          <Link to="/shop">
            <button className="shop-btn">SHOP</button>
          </Link>

          {/* Lien avec icône X.com */}
          <a href="https://x.com/X7links" target="_blank" rel="noopener noreferrer" className="x-btn" style={{
            backgroundColor: 'transparent',
            position: 'absolute',
            right: '10px',
            top: '50%',
            transform: 'translateY(-50%)',
          }}>
            <img src="https://i.postimg.cc/zvStTK4S/x-com-ico.png" alt="X logo" style={{ width: '32px', height: '32px', borderRadius: '50%' }} />
          </a>
          {/* Lien avec icône TELEGRAM */}
          <a href="https://t.me/x7link" target="_blank" rel="noopener noreferrer" className="x-btn" style={{
            backgroundColor: 'transparent',
            position: 'absolute',
            right: '48px',
            top: '50%',
            transform: 'translateY(-50%)',
          }}>
            <img src="https://i.postimg.cc/52GSDXm5/ico-telegram-good.png" alt="X logo" style={{ width: '32px', height: '32px', borderRadius: '50%' }} />
          </a>
        </nav>

        {/* Timer */}
        <div className="text-center py-1 custom-bar-background">
          <span style={{ color: 'black', fontWeight: 'bold' }}>Next week's rankings: </span>
          <span style={{ color: 'white', fontWeight: 'bold' }}>{days}d {hours}h {minutes}m {seconds}s</span>
        </div>


        {/* Routes */}
       

        <Switch>
          <Route exact path="/" component={Vignettes} /> {/* Page d'accueil */}
          <Route path="/general-terms" component={GeneralTerms} />
          <Route path="/shop" component={ShopPage} /> {/* Page Shop */}
          <Route path="/lesbian" component={LesbianVignettes} /> {/* Page Lesbian */}
          <Route path="/solo-girl" component={SoloGirlVignettes} /> {/* Page Solo Girl */}
          <Route path="/threesome" component={ThreesomeVignettes} /> {/* Page Threesome */}
          <Route path="/big-ass" component={BigAssVignettes} /> {/* Page Big Ass */}
          <Route path="/teen" component={TeenVignettes} /> {/* Page Teen */}
          <Route path="/blow-job" component={BlowJobVignettes} /> {/* Page Blow Job */}
          <Route path="/milf" component={MilfVignettes} /> {/* Page Milf */}
          <Route path="/bonus" component={BonusVignettes} /> {/* Page Bonus */}
          <Route path="/cam-girl" component={camgirlVignettes} /> {/* Page CamGirl */}
          <Route path="/SoundPorn" component={SoundPornVignettes} />
          <Route path="/Anime" component={AnimeVignettes} />
          <Route path="/Onlyfan" component={OnlyfanVignettes} />
          <Route path="/GamePorn" component={GamePornVignettes} />
          <Route path="/VRPorn" component={VRPornVignettes} />
          <Route path="/SexDoll" component={SexDollVignettes} />
          <Route path="/BlogPage" component={BlogPage} />
          <Route path="/SexMap" component={MapPage} />
          <Route path="/AdvertiserPage" component={AdvertiserPage} />
          

        </
        Switch>
      {/* Footer + TEXT (voir css) */}
    <div>
      <h1></h1>
      <AdBanner />
    </div>

        {/* Footer + TEXT (voir css) */}
<footer className="text-center bg-dark py-2 mt-auto" style={{ lineHeight: '0.4' }}>
  {/* Champ d'inscription à la newsletter avec Formspree */}
  <form
    action="https://formspree.io/f/xrbgbqvy" // Remplacez YOUR_FORM_ID par l'ID de votre formulaire Formspree
    method="POST"
    style={{ display: 'inline-block', margin: '10px 0' }}
  >
    <p>Get updates on the top 7 porn videos ranked!</p>
    <input
      type="email"
      name="email"
      placeholder="user@email.com"
      aria-label="Email address for newsletter"
      required
      style={{
        padding: '10px',
        borderRadius: '5px',
        border: '1px solid #ffa31a',
        width: '250px',
        marginRight: '1rem',
        outline: 'none',
        fontSize: '14px',
        marginBottom: '0.8rem',
        marginRight: '0.3rem',
        
      }}
    />
    <button
      type="submit"
      style={{
        padding: '10px 4px',
        borderRadius: '10px',
        border: 'none',
        backgroundColor: '#ffa31a',
        color: '#fff',
        cursor: 'pointer',
        fontSize: '14px',
        transition: 'background-color 0.3s',
        marginBottom: '0.2rem',
      }}
      onMouseOver={(e) => (e.target.style.backgroundColor = '#e68a00')}
      onMouseOut={(e) => (e.target.style.backgroundColor = '#ffa31a')}
    >
      Subscribe
    </button>
  </form>

  <p style={{ marginBottom: '5px' }}>
    <span style={{ fontSize: '18px' }}>&#9734;</span> Support us 100% free website !  <span style={{ fontSize: '18px' }}>&#9734;</span>
  </p>
  <p style={{ marginBottom: '5px' }}>
    <span style={{ fontSize: '18px' }}>&#8383;</span> :
    <a
      href="https://www.blockchain.com/explorer/addresses/btc/bc1qd3gcvvk5aafmu2sdt7f6k93npk4mu7wa3gepaa"
      target="_blank"
      rel="noopener noreferrer"
      style={{ lineHeight: '1.9', color: '#ffa31a', textDecoration: 'none', fontSize: '14px' }}
    >
      Donate to a bitcoin address
    </a>
  </p>

  <p style={{ marginBottom: '5px' }}>
  © 2024 x7links. All rights reserved. <Link to="/general-terms" style={{ color: '#ffa31a', textDecoration: 'none' }}>terms</Link>
</p>

<p style={{ marginTop: '18px', marginBottom: '30px' }}>
   <Link to="/blogpage" style={{ color: '#ffa31a', textDecoration: 'none' }}> - Visit Our Blog - </Link>
</p>
  
  
  </footer>
      </div>
    </Router>
    );
  }


export default App;
