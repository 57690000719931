import React, { useEffect, useState } from 'react';
import './BlogPage.css';
import { Helmet } from 'react-helmet';

const BlogPage = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    // Simuler la récupération des articles de blog
    const samplePosts = [
      { title: 'The Art of Sensual Sound', date: 'Nov 5, 2024', summary: 'Discover how sound enhances intimacy.', imgSrc: 'path/to/image1.jpg' },
      { title: 'Exploring Erotic ASMR', date: 'Oct 29, 2024', summary: 'An insight into the popular ASMR trend.', imgSrc: 'path/to/image2.jpg' },
      // Ajoutez d'autres articles ici
    ];
    setPosts(samplePosts);
  }, []);

  return (
    <div className="blog-page">
      <Helmet>
        <title>Blog - X7links</title>
        <meta name="description" content="Explore our collection of blog articles, featuring insights, stories, and news on the latest trends in adult entertainment." />
      </Helmet>
      
      <header className="blog-header">
        <h1>Welcome to Our Blog</h1>
        <p>Explore the world of sensuality and sound in our curated articles.</p>
      </header>

      <div className="blog-grid">
        {posts.map((post, index) => (
          <div key={index} className="blog-card">
            <img src={post.imgSrc} alt={post.title} className="blog-image" />
            <div className="blog-content">
              <h2>{post.title}</h2>
              <p className="blog-date">{post.date}</p>
              <p className="blog-summary">{post.summary}</p>
              <button className="read-more">Read More</button>
            </div>
          </div>
        ))}
      </div>
      
      <footer className="blog-footer">
        <p>© 2024 X7links. All Rights Reserved.</p>
      </footer>
    </div>
  );
};

export default BlogPage;
