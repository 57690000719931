import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
//----------------CSS IMPORT-------------------//
import './App.css';
import './styles.css';



ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// Supprimez ou commentez l'appel à reportWebVitals si non nécessaire
// reportWebVitals();
